import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import TableYields from "./TableYields";
import RealmService from "./RealmService";
import CredentialsAPI from "../Credentials/CredentialsAPI";


const auctionsService = new RealmService('test-cbgji');

const Yields = () => {
  const [queryResult, setQueryResult] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await auctionsService.initialize('test-cbgji', CredentialsAPI.API_AUCTIONS);

      const mongodb = await auctionsService.getMongoClient();
      if (mongodb) {
        const collection = mongodb.db('Finance').collection('Yields');

        // Perform queries or operations on the collection

        // Example: Fetch all documents from the collection
        const documents = await collection.find();
        setQueryResult(documents);
      }
    };

    fetchData();
  }, []);


        return (
            <Container fluid className="project-section">
                <Container><h1 className="project-heading">
                    <strong>Yields</strong>
                </h1>
                    <Row style={{justifyContent: "center", paddingBottom: "10px"}}>
                        <Col md={12} sm={6}>
                            <div style={{overflow: "auto", alignContent:"center"}}>

                                <TableYields data={queryResult} style={{color: "white"}}/>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </Container>
        );
};

export default Yields;
