import React, {useEffect, useState} from 'react';
import data from "./concerts.json"

function Table() {
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        setTableData(data['concerts']);
    }, []);

    const [filter, setFilter] = useState('');

    useEffect(() => {
        setTableData(data['concerts']);
    }, []);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = tableData.filter((item) => {
        const today = new Date();
        const itemDate = new Date(item.date);
        return item.main.toLowerCase().includes(filter.toLowerCase()) && itemDate > today;
    }).slice(0,50);


    return (<div>
        <input type="text"  className={'small'} value={filter} onChange={handleFilterChange} placeholder="Search"/>
    <p>
    </p>
        <table  className={'small'} style={{color: "white", margin: "100px"}}>
            <thead>
            <tr>
                <th>Artist</th>
                <th>Concert</th>
                <th>Date</th>
                <th>Artists</th>
                <th>Location</th>
                <th>Details</th>
            </tr>
            </thead>
            <tbody  className="very-small" style={{textAlign:'left'}}>
            {filteredData.map((item, index) => (<tr key={index}>
                <td style={{padding: '3px'}}>{item.main} </td>
                <td> {item.concert}</td>
                <td style={{padding: '3px'}}> {new Date(item.date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })}</td>
                <td className="very-small" style={{wordWrap: 'break-word', justifyContent: 'left', padding: '3px'}}>{item.artists}</td>
                <td style={{padding: '3px'}}>{item.location}</td>
                <td style={{padding: '3px'}}>{item.location_details}</td>

            </tr>))}
            </tbody>
        </table>
    </div>);
}

export default Table;