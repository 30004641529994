import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Type from "./Type";

function Home() {
    return (
        <section>
            <Container fluid className="home-section" id="home">
                <Container className="home-content">
                    <Row>
                        <Col md={12} className="home-header">
                            <h1 style={{padding: 50}} className="heading" style={{textAlign: 'center'}}>
                                Invest | Mr Freire
                            </h1>
                            <div>
                            <h5 style={{padding: 100}} style={{textAlign: 'center'}}>
                                Created by Javier Freire
                            </h5>
                                                          </div>


                            <div style={{padding: 150, textAlign: "center"}}>
                                <Type/>
                            </div>

                        </Col>

                    </Row>
                </Container>
            </Container>
        </section>
    );
}

export default Home;
