import React, {useEffect, useState} from 'react';

function Table({data}) {
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (data && Array.isArray(data)) { // Check if data is defined and an array
            setTableData(data);
            console.log(data)
        }
    }, [data]);

    const isMobile = window.innerWidth <= 768; // Example mobile width threshold

    const tableClassName = isMobile ? 'small' : 'big';
    const baseUrl = 'finance.mrfreire.net/security_search/security_search_results/'


    return (<div>
        <p>
        </p>
        <table className={tableClassName} style={{color: "white", margin: "50px", alignContent: "center"}}>
            <thead>
            <tr>
                <th>Ticker</th>
                <th>Ex Dividend</th>
                <th>Pay date</th>
                <th>Amount</th>
            </tr>
            </thead>
            <tbody className="small" style={{textAlign: 'left', padding: "10px"}}>
            {tableData.map((item, index) => (
                <tr key={index}>
                    <td style={{padding: '30px'}}>
                        <a href={`https://finance.mrfreire.net/security_search/security_search_results/${item.ticker}`}>{item.ticker}</a>
                    </td>
                    <td style={{padding: '30px'}}>{item.ex_dividend_date}</td>
                    <td style={{padding: '30px'}}>{item.pay_date}</td>
                    <td style={{padding: '30px'}}>{item.cash_amount}</td>


                </tr>
            ))}
            </tbody>

        </table>
    </div>);
}

export default Table;