import React, {useEffect} from 'react';
import {Container} from "react-bootstrap";

function Locations() {

    useEffect(() => {
        const loadTableauScript = () => {
            const scriptElement = document.createElement('script');
            scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
            scriptElement.async = true;
            scriptElement.onload = () => {
                const divElement = document.getElementById('viz1685917084334');
                const vizElement = divElement.getElementsByTagName('object')[0];
                vizElement.style.width = '100%';
                vizElement.style.height = (divElement.offsetWidth * 0.75) + 'px';
            };
            document.body.appendChild(scriptElement);
        };

        const renderTableauViz = () => {
            const placeholderElement = document.getElementById('viz1685917084334');
            const objectElement = placeholderElement.getElementsByTagName('object')[0];
            objectElement.style.display = 'block';
        };

        loadTableauScript();
        renderTableauViz();
    }, []);

    return (
        <Container fluid className="project-section">
            <h1 className="project-heading">
                <strong>Location</strong>
            </h1>
            <p></p>
            <div
                className="tableauPlaceholder"
                id="viz1685917084334"
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}
            >
                <noscript>
                    <a href="#">
                        <img
                            alt="Companies Map"
                            src="https://public.tableau.com/static/images/Fi/FinanceCompaniesMap/Sheet1/1_rss.png"
                            style={{border: 'none'}}
                        />
                    </a>
                </noscript>
                <object className="tableauViz" style={{display: 'none'}}>
                    <param
                        name="host_url"
                        value="https%3A%2F%2Fpublic.tableau.com%2F"
                    />
                    <param name="embed_code_version" value="3"/>
                    <param name="site_root" value=""/>
                    <param name="name" value="FinanceCompaniesMap/Sheet1"/>
                    <param name="tabs" value="no"/>
                    <param name="toolbar" value="yes"/>
                    <param
                        name="static_image"
                        value="https://public.tableau.com/static/images/Fi/FinanceCompaniesMap/Sheet1/1.png"
                    />
                    <param name="animate_transition" value="yes"/>
                    <param name="display_static_image" value="yes"/>
                    <param name="display_spinner" value="yes"/>
                    <param name="display_overlay" value="yes"/>
                    <param name="display_count" value="yes"/>
                    <param name="language" value="en-GB"/>
                    <param name="filter" value="publish=yes"/>
                </object>
                <script type="text/javascript">
                    {`
          var divElement = document.getElementById('viz1685917084334');
          var vizElement = divElement.getElementsByTagName('object')[0];
          vizElement.style.width = '100%';
          vizElement.style.height = '100%;
          var scriptElement = document.createElement('script');
          scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
          vizElement.parentNode.insertBefore(scriptElement, vizElement);
        `}
                </script>
            </div>
        </Container>
    );
}

export default Locations;
