import React from "react";
import Card from "react-bootstrap/Card";

function CryptoCard(props) {


    return (
        <a href={props.website} target="_blank" rel="noopener noreferrer">
            <Card className="book-card-view">
                <a href={props.website} target="_blank" rel="noopener noreferrer">{props.name}</a>

                <Card.Body className={"very-small"} style={{textAlign: "left"}}>
                    <strong>{props.summaryCrypto}</strong>
                </Card.Body>
                <a href={props.technical} target="_blank" rel="noopener noreferrer"><small>Technical Paper</small></a>
                <a href={props.reddit} target="_blank" rel="noopener noreferrer"><small>Reddit</small></a>

                {props.exchanges && props.exchanges.length > 0 && (
                    <Card.Body className="very-small" style={{textAlign: "justify", height: "20em"}}>
                        <strong>Exchanges</strong>
                        <p></p>
                        <ul>
                            {props.exchanges.split(" ").map((exchange, index) => (
                                <li key={index}>
                                    {exchange}
                                </li>
                            ))}
                        </ul>
                    </Card.Body>
)}

            </Card>
        </a>
    );
}

export default CryptoCard;
