import React, { useEffect, useState } from "react";
import * as Realm from "realm-web";

class RealmService {
  constructor(appId) {
    this.app = new Realm.App({ id: appId });
  }

  async initialize(appId, key) {
    try {
      const appConfig = {
        id: appId,
        timeout: 10000, // Optional: Set the timeout for network requests (in milliseconds)
        app: {
          name: "My Realm App",
          version: "1.0",
        },
      };

      // Initialize the Realm app
      this.app = new Realm.App(appConfig);

      // Authenticate the user anonymously
      const credentials = Realm.Credentials.apiKey(key);
      await this.app.logIn(credentials);
    } catch (error) {
      console.error("Error during app initialization:", error);
    }
  }

  async getMongoClient() {
    try {
      // Ensure the user is logged in before accessing the MongoDB client
      if (!this.app.currentUser) {
        throw new Error("User not logged in");
      }

      const mongodb = this.app.currentUser.mongoClient("mongodb-atlas");
      return mongodb;
    } catch (error) {
      console.error("Error getting the MongoDB client:", error);
      return null;
    }
  }
}

export default RealmService;
