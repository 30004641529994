import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import Table from "../Trending/Table";
import axios from "axios";

const Hot = () => {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);

    useEffect(() => {
        const url = 'https://api.stocktwits.com/api/2/trending/symbols.json';
        const api_key = '594a006d-1e3d-464d-8321-7e76a7c0d44c';
  const start = 1;
  const limit = 200;
  const sort = 'volume_24h';
  const sort_dir = 'desc';
  const convert = 'USD';

        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const jsonData = await response.json();
                const today = new Date();

                const uniqueData = Array.from(new Set(jsonData['symbols']));
                setData(uniqueData)
            } catch (error) {
                console.error('Error:', error);
            }
        };


const fetchData2 = async () => {
  try {
    const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
    const apiUrl = `https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest?sort=${sort}&sort_dir=${sort_dir}&start=${start}&limit=${limit}&convert=${convert}`;
    const headers = { 'X-CMC_PRO_API_KEY': api_key };

    const response = await axios.get(proxyUrl + apiUrl, { headers });

    // Process the response data as needed
    console.log(response.data);
    const uniqueData = Array.from(new Set(response.data['data']));
    setData2(uniqueData)
  } catch (error) {
    console.error(error);
  }
};

        fetchData();
        fetchData2();
    }, []);

    return (
        <Container fluid className="project-section">
            <Container><h1 className="project-heading">
                <strong>Trending</strong>
            </h1>
                <Row style={{justifyContent: "center", paddingBottom: "10px"}}>
                    <Col md={12} sm={6}>
                        <div style={{overflow: "auto", alignContent: "center"}}>

                            <Table data={data} data2={data2} style={{color: "white"}}/>
                        </div>


                    </Col>
                </Row>

            </Container>
        </Container>
    );
};

export default Hot;
