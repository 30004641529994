import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import {BsBook, BsGithub} from "react-icons/bs";

function BookCard(props) {
  return (
      <a href={props.link} target="_blank" rel="noopener noreferrer">
    <Card className="book-card-view" >
      <Card.Img  src={props.imgPath}  alt="card-img-book" className={"card-img-book"}/>
        <Card.Body className={"very-small"} style={{height: "40px", textAlign: "justify"}}>
            <strong>{props.title}</strong> </Card.Body>
        <Card.Body className={"very-small"} style={{ textAlign: "justify" }}>
            <strong>{props.author}</strong>
        </Card.Body>
          <Card.Body className={"very-small"}  style={{ textAlign: "justify",  height: "20em" }}>
              {props.description.split(' ').slice(0, 100).join(' ') + '...'}
          </Card.Body>
<p></p>

    </Card>
        </a>
  );
}
export default BookCard;
