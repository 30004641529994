import React, {useEffect, useState} from 'react';
import ReactCountryFlag from "react-country-flag";

function Table({data}) {
    const [tableData, setTableData] = useState([]);
    const [filter, setFilter] = useState('');


    useEffect(() => {
        setTableData(data);
    }, []);


    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = data.sort((a, b) => a.country.localeCompare(b.country));


    const isMobile = window.innerWidth <= 768; // Example mobile width threshold

    const tableClassName = isMobile ? 'small' : 'big';


    return (<div>

        <table className={tableClassName} style={{color: "white", margin: "50px", alignContent: "center"}}>
            <thead>
            <tr>
                <th>Country</th>
                <th>Yield</th>
            </tr>
            </thead>
            <tbody className="small" style={{textAlign: 'left', padding: "10px", alignContent:"center"}}>
            {filteredData.map((item, index) => (
                <tr key={index}>
                    <td style={{padding: '20px'}}>{item.country}</td>
                    <td style={{padding: '20px'}}>{item.yield}</td>

                </tr>
            ))}
            </tbody>

        </table>
    </div>);
}

function retrieveCountryCode(country) {
    switch (country) {
        case "US":
            return "US";
        case "UK":
            return "GB";
        case "Germany":
            return "DE";
        case "France":
            return "FR";
        case "Italy":
            return "IT";
        case "Spain":
            return "ES";
        case "Netherlands":
            return "NL";
        case "Belgium":
            return "BE";
        case "Austria":
            return "AT";
        case "Portugal":
            return "PT";
        case "Finland":
            return "FI";
        case "Ireland":
            return "IE";
        case "Greece":
            return "GR";
        case "Luxembourg":
            return "LU";
        case "Slovenia":
            return "SI";
        case "Slovakia":
            return "SK";
        case "Estonia":
            return "EE";
        case "Singapore":
            return "SG";
        case "Canada":
            return "CA";
        case "Australia":
            return "AU";
        case "New Zealand":
            return "NZ";
        case "Japan":
            return "JP";
        case "Denmark":
            return "DK";
        case "Sweden":
            return "SE";

    }
}

export default Table;