import React, {useEffect, useState} from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import Books from "./components/Books/Books";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Concerts from "./components/Concerts/Concerts";
import Courses from "./components/Courses/Courses";
import Current from "./components/Current/Current";
import Yields from "./components/Auctions/Yields";
import Auctions from "./components/Auctions/Auctions";
import BondFunds from "./components/Funds/BondFunds";
import Dividends from "./components/Dividends/Dividends";
import Trending from "./components/Trending/Trending";
import Crypto from "./components/Crypto/Crypto";
import Europe from "./components/Locations/Europe";
import SouthAmerica from "./components/Locations/SouthAmerica";
import Americas from "./components/Locations/Americas";
import Oceania from "./components/Locations/Oceania";
import Asia from "./components/Locations/Asia";
import Japan from "./components/Locations/Japan";
import Locations from "./components/Locations/Locations";

function App() {
    const [load, upadateLoad] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            upadateLoad(false);
        }, 1200);

        return () => clearTimeout(timer);
    }, []);

    return (<Router>
        <Preloader load={load}/>
        <div className="App" id={load ? "no-scroll" : "scroll"}>
            <Navbar/>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/project" element={<Projects/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/resume" element={<Resume/>}/>
                <Route path="/books" element={<Books/>}/>
                <Route path="/concerts" element={<Concerts/>}/>
                <Route path="/courses" element={<Courses/>}/>
                <Route path="/current" element={<Current/>}/>
                <Route path="/auctions" element={<Auctions/>}/>
                <Route path="/yields" element={<Yields/>}/>
                <Route path="/bond-funds" element={<BondFunds/>}/>
                <Route path="/dividends" element={<Dividends/>}/>
                <Route path="/trending" element={<Trending/>}/>
                <Route path="/crypto" element={<Crypto/>}/>
                <Route path="/map" element={<Locations/>}/>

                <Route path="*" element={<Navigate to="/"/>}/>

            </Routes>
            <Footer/>
        </div>
    </Router>);
}

export default App;
