import React, {useEffect, useState} from "react";
import {Col, Container, Nav, Row, Tab} from "react-bootstrap";
import Table from "./Table";
import RealmService from "../Auctions/RealmService";
import CredentialsAPI from "../Credentials/CredentialsAPI";


const auctionsService = new RealmService('funds-ekuzl');

const BondFunds = () => {
    const [queryResult, setQueryResult] = useState([]);
    const [queryResult2, setQueryResult2] = useState([]);
    const [queryResult3, setQueryResult3] = useState([]);
    const [queryResult4, setQueryResult4] = useState([]);
    const [queryResult5, setQueryResult5] = useState([]);
    const [queryResult6, setQueryResult6] = useState([]);
    const [queryResult7, setQueryResult7] = useState([]);

    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        const fetchData = async () => {
            await auctionsService.initialize('funds-ekuzl', CredentialsAPI.API_FUNDS);

            const mongodb = await auctionsService.getMongoClient();
            if (mongodb) {
                const collection = mongodb.db('Finance').collection('Fund_Holdings');

                const collection_company = mongodb.db('Finance').collection('Company_Info');

                const triple_a = await collection.find({'grades.0.value': {'$gt': 80}}, {'ticker': 1, '_id': 0})
                const double_a = await collection.find({'grades.1.value': {'$gt': 70}}, {'ticker': 1, '_id': 0})
                const single_a = await collection.find({'grades.2.value': {'$gt': 50}}, {'ticker': 1, '_id': 0})

                const triple_b = await collection.find({'grades.3.value': {'$gt': 40}}, {'ticker': 1, '_id': 0})
                const double_b = await collection.find({'grades.4.value': {'$gt': 30}}, {'ticker': 1, '_id': 0})
                const single_b = await collection.find({'grades.5.value': {'$gt': 30}}, {'ticker': 1, '_id': 0})
                const below_b = await collection.find({'grades.6.value': {'$gt': 17}}, {'ticker': 1, '_id': 0})

                const triple_a_doc = await collection_company.find(
                    {ticker: {$in: triple_a.map(x => x.ticker)}},
                    {ticker: 1, longName: 1, _id: 0, morningstar: 1}
                );

                const double_a_doc = await collection_company.find(
                    {ticker: {$in: double_a.map(x => x.ticker)}},
                    {ticker: 1, longName: 1, _id: 0, morningstar: 1}
                );

                const single_a_doc = await collection_company.find(
                    {ticker: {$in: single_a.map(x => x.ticker)}},
                    {ticker: 1, longName: 1, _id: 0, morningstar: 1}
                );

                const triple_b_doc = await collection_company.find(
                    {ticker: {$in: triple_b.map(x => x.ticker)}},
                    {ticker: 1, longName: 1, _id: 0, morningstar: 1}
                );


                const double_b_doc = await collection_company.find(
                    {ticker: {$in: double_b.map(x => x.ticker)}},
                    {ticker: 1, longName: 1, _id: 0, morningstar: 1}
                );

                const single_b_doc = await collection_company.find(
                    {ticker: {$in: single_b.map(x => x.ticker)}},
                    {ticker: 1, longName: 1, _id: 0, morningstar: 1}
                );

                const below_b_doc = await collection_company.find(
                    {ticker: {$in: below_b.map(x => x.ticker)}},
                    {ticker: 1, longName: 1, _id: 0, morningstar: 1}
                );


                setQueryResult(triple_a_doc);
                setQueryResult2(double_a_doc);
                setQueryResult3(single_a_doc);
                setQueryResult4(triple_b_doc);
                setQueryResult5(double_b_doc);
                setQueryResult6(single_b_doc);
                setQueryResult7(below_b_doc);

            }
        };

        fetchData();
    }, []);


    return (
        <div>
            <Container fluid className="project-section">
                <Container><h1 className="project-heading">
                    <strong>Bond Funds</strong>
                </h1>
                    <Row style={{justifyContent: "center", paddingBottom: "10px"}}>
                        <Col md={12} sm={6}>

                            <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab1">AAA</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab2">AA</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab3">A</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab4">BBB</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab5">BB</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab6">B</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab7">Junk</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <p></p>
                                    <Tab.Pane eventKey="tab1">
                                        {/* Content for Tab 1 */}
                                        <div style={{overflow: "auto", alignContent: "center"}}>
                                            <Table data={queryResult} style={{color: "white"}}/>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab2">
                                        {/* Content for Tab 2 */}
                                        <div style={{overflow: "auto", alignContent: "center"}}>
                                            <Table data={queryResult2} style={{color: "white"}}/>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab3">
                                        {/* Content for Tab 3 */}
                                        <div style={{overflow: "auto", alignContent: "center"}}>
                                            <Table data={queryResult3} style={{color: "white"}}/>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="tab4">
                                        {/* Content for Tab 1 */}
                                        <div style={{overflow: "auto", alignContent: "center"}}>
                                            <Table data={queryResult4} style={{color: "white"}}/>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab5">
                                        <div style={{overflow: "auto", alignContent: "center"}}>
                                            <Table data={queryResult5} style={{color: "white"}}/>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab6">
                                        <div style={{overflow: "auto", alignContent: "center"}}>
                                            <Table data={queryResult6} style={{color: "white"}}/>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab7">
                                        <div style={{overflow: "auto", alignContent: "center"}}>
                                            <Table data={queryResult7} style={{color: "white"}}/>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>


                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
};


export default BondFunds;
