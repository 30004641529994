import React, {useEffect, useState} from 'react';
import ReactCountryFlag from "react-country-flag";

function Table({data}) {
    const [tableData, setTableData] = useState([]);
    const [filter, setFilter] = useState('');


    useEffect(() => {
        setTableData(data);
    }, []);


    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = data.filter((item) => {
        const today = new Date();
        const itemDate = new Date(item.auction_date);
        return item.country.toLowerCase().includes(filter.toLowerCase()) && itemDate > today
    }).sort((a, b) => a.auction_date - b.auction_date);

    const isMobile = window.innerWidth <= 768; // Example mobile width threshold

    const tableClassName = isMobile ? 'small' : 'big';


    return (<div>
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Search"/>
        <p>
        </p>
        <table className={tableClassName} style={{color: "white", margin: "50px", alignContent: "center"}}>
            <thead>
            <tr>
                <th>Auction Date</th>
                <th>Country</th>
                <th>Name</th>
                <th>ISIN</th>
                <th>Redemption date</th>
            </tr>
            </thead>
            <tbody className="small" style={{textAlign: 'left', padding: "10px"}}>
            {filteredData.map((item, index) => (
                <tr key={index}>
                    <td style={{
                        padding: '20px',
                        textAlign: 'right'
                    }}>{item.auction_date ? new Date(item.auction_date).toLocaleString().split(',')[0] : ''}</td>
                    <td style={{padding: '10px', textAlign: 'letft'}}>{item.country} <ReactCountryFlag
                        countryCode={retrieveCountryCode(item.country)}/>
                    </td>
                    <td style={{padding: '20px'}}>{item.name}</td>

                    <td style={{padding: '20px', textAlign: 'right'}}>{item.isin}</td>
                    <td style={{
                        padding: '20px',
                        textAlign: 'right'
                    }}>{item.redemption_date ? new Date(item.redemption_date).toLocaleString().split(',')[0] : ''}</td>


                </tr>
            ))}
            </tbody>

        </table>
    </div>);
}

function retrieveCountryCode(country) {
    switch (country) {
        case "US":
            return "US";
        case "UK":
            return "GB";
        case "Germany":
            return "DE";
        case "France":
            return "FR";
        case "Italy":
            return "IT";
        case "Spain":
            return "ES";
        case "Netherlands":
            return "NL";
        case "Belgium":
            return "BE";
        case "Austria":
            return "AT";
        case "Portugal":
            return "PT";
        case "Finland":
            return "FI";
        case "Ireland":
            return "IE";
        case "Greece":
            return "GR";
        case "Luxembourg":
            return "LU";
        case "Slovenia":
            return "SI";
        case "Slovakia":
            return "SK";
        case "Estonia":
            return "EE";
        case "Singapore":
            return "SG";
        case "Canada":
            return "CA";
        case "Australia":
            return "AU";
        case "New Zealand":
            return "NZ";
        case "Japan":
            return "JP";
        case "Denmark":
            return "DK";
        case "Sweden":
            return "SE";
        case "Argentina":
            return "AR";
        case "Czech Republic":
            return "CZ";
        case "Korea":
            return "KR";
            case "Norway":
            return "NO";

    }
}

export default Table;