import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import Table from "../Dividends/Table";

const Dividends = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const url = 'https://api.polygon.io/v3/reference/dividends?limit=1000&apiKey=MtzArpGmqzBWLuPIh0p4r9GNhoAFPgg4';

        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const jsonData = await response.json();
                const today = new Date();

                const uniqueData = Array.from(new Set(jsonData['results']));
const filteredData = uniqueData
  .filter((item, index, self) => {
    const pay_date = new Date(item.pay_date);
    return (
      index === self.findIndex(
        (t) => t.ticker === item.ticker && pay_date > today
      )
    );
  })
  .sort((a, b) => {
    const dateA = new Date(a.pay_date);
    const dateB = new Date(b.pay_date);
    return dateA - dateB;
  })
  .slice(0, 1000);
setData(filteredData)
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <Container fluid className="project-section">
            <Container><h1 className="project-heading">
                <strong>Dividends</strong>
            </h1>
                <Row style={{justifyContent: "center", paddingBottom: "10px"}}>
                    <Col md={12} sm={6}>
                        <div style={{overflow: "auto", alignContent: "center"}}>

                            <Table data={data} style={{color: "white"}}/>
                        </div>
                    </Col>
                </Row>

            </Container>
        </Container>
    );
};

export default Dividends;
