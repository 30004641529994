import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import BookCard from "./BookCard";
import data from "./books.json"

function Books() {


    let books = [];
    books = (data["books"])
    fetch(data)
        .then(response => {
            //console.log(response); // log the response
            return response.json();
        })
        .then(data => {
            //console.log(data); // log the parsed JSON data
            // do something with the data
        })
        .catch(error => {
            //console.error('Error reading file:', error);
        });

    return (
        <Container fluid className="project-section">
            <Container>
                <h1 className="project-heading">
                    My Recent Books
                </h1>
                <Row style={{justifyContent: "center", paddingBottom: "10px"}}>


                    {books.map((book, index) => (
                        <Col lg={3} md={4} sm={6} xs={12} className="book-card">
                            <BookCard
                                isBlog={false}
                                title={book.title}
                                author={book.author}
                                description={book.description}
                                link={book.link}
                                imgPath={book.cover}
                            />
                        </Col>
                    ))}





                </Row>
            </Container>
        </Container>
    );
}

export default Books;
