import React from "react";
import Typewriter from "typewriter-effect";

function TypeCurrent() {
    return (
        <Typewriter
            options={{
                strings: [
                    "Nutrition",
                    "React",
                    "Machine Learning",
                    "Blockchain",
                    "Deep Learning",
                    "Javascript"
                ],
                autoStart: true,
                loop: true,
                deleteSpeed: 50,
            }}
        />
    );
}

export default TypeCurrent;
