import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import TypeCurrent from "./TypeCurrent";

function Current() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={12} className="home-header" >
              <h2 style={{ paddingBottom: 15 }} className="heading" style={{ textAlign: 'center'}} >
              Currently learning
              </h2>


              <div style={{ padding: 150, textAlign: "center" }}>
                <TypeCurrent />
              </div>
            </Col>

          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Current;
