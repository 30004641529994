import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
    return (
        <Typewriter
            options={{
                strings: [
                    "Investing",
                    "Diversification",
                    "Knowledge",
                    "Finance",
                    "Bonds",
                    "Stocks",
                    "ETFs",
                    "Mutual Funds",
                    "Options",
                    "Futures",
                    "Commodities",
                    "Cryptocurrencies",
                    "Real Estate",
                    "Portfolio construction",
                    "Risk management"
                ],
                autoStart: true,
                loop: true,
                deleteSpeed: 50,
            }}
        />
    );
}

export default Type;
