import React, {useEffect, useState} from 'react';

function Table({data}) {
    const [tableData, setTableData] = useState([]);
    const [filter, setFilter] = useState('');


    useEffect(() => {
        setTableData(data);
    }, []);


    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = data.filter((item) => {
        return item.ticker.toLowerCase().includes(filter.toLowerCase())
    }).sort((a, b) => a.ticker - b.ticker);

    const isMobile = window.innerWidth <= 768; // Example mobile width threshold

    const tableClassName = isMobile ? 'small' : 'big';
const baseUrl = 'finance.mrfreire.net/security_search/security_search_results/'


    return (<div>
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Search"/>
        <p>
        </p>
        <table className={tableClassName} style={{color: "white", margin: "50px", alignContent: "center"}}>
            <thead>
            <tr>
                <th>Ticker</th>
                <th>Name</th>
                <th>Morningstar</th>
            </tr>
            </thead>
            <tbody className="small" style={{textAlign: 'left', padding: "10px"}}>
            {filteredData.map((item, index) => (
                <tr key={index}>


<td style={{ padding: '7px' }}>
  <a href={`https://finance.mrfreire.net/security_search/security_search_results/${item.ticker}`}>{item.ticker}</a>
</td>
                    <td style={{padding: '7px', textAlign: 'left'}}>{item.longName}</td>
                    <td style={{padding: '7px', textAlign: 'right'}}>{item.morningstar}</td>

                </tr>
            ))}
            </tbody>

        </table>
    </div>);
}

function retrieveCountryCode(country) {
    switch (country) {
        case "US":
            return "US";
        case "UK":
            return "GB";
        case "Germany":
            return "DE";
        case "France":
            return "FR";
        case "Italy":
            return "IT";
        case "Spain":
            return "ES";
        case "Netherlands":
            return "NL";
        case "Belgium":
            return "BE";
        case "Austria":
            return "AT";
        case "Portugal":
            return "PT";
        case "Finland":
            return "FI";
        case "Ireland":
            return "IE";
        case "Greece":
            return "GR";
        case "Luxembourg":
            return "LU";
        case "Slovenia":
            return "SI";
        case "Slovakia":
            return "SK";
        case "Estonia":
            return "EE";
        case "Singapore":
            return "SG";
        case "Canada":
            return "CA";
        case "Australia":
            return "AU";
        case "New Zealand":
            return "NZ";
        case "Japan":
            return "JP";
        case "Denmark":
            return "DK";
        case "Sweden":
            return "SE";

        case "Poland":
            return "PL";

    }
}

export default Table;