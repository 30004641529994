import React, {useEffect, useState} from 'react';
import {Col, Container, Nav, Row, Tab} from "react-bootstrap";

function Table({data, data2}) {
    const [tableData, setTableData] = useState([]);
    const [tableData2, setTableData2] = useState([]);
    const [activeTab, setActiveTab] = useState('tab1');

        const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        if (data && Array.isArray(data)) { // Check if data is defined and an array
            setTableData(data);
            console.log(data)
        }
    }, [data]);

        useEffect(() => {
        if (data && Array.isArray(data2)) { // Check if data is defined and an array
            setTableData2(data2);
            console.log(data2)
        }
    }, [data2]);

    const isMobile = window.innerWidth <= 768; // Example mobile width threshold

    const tableClassName = isMobile ? 'small' : 'big';
    const baseUrl = 'finance.mrfreire.net/security_search/security_search_results/'




    return (
        <div>
            <Container fluid className="project-section">
                <Container>
                    <Row style={{justifyContent: "center", paddingBottom: "10px"}}>
                        <Col md={12} sm={6}>

                            <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab1">Trending Assets</Nav.Link>
                                    </Nav.Item>
                                    {/*<Nav.Item>*/}
                                    {/*    <Nav.Link eventKey="tab2">AA</Nav.Link>*/}
                                    {/*</Nav.Item>*/}
                                </Nav>
                                <Tab.Content>
                                    <p></p>
                                    <Tab.Pane eventKey="tab1">
                                        {/* Content for Tab 1 */}
                                        <table className={tableClassName}
                                               style={{color: "white", margin: "50px", alignContent: "center"}}>
                                            <thead>
                                            <tr>
                                                <th>Ticker</th>
                                                <th>Name</th>
                                            </tr>
                                            </thead>
                                            <tbody className="small" style={{textAlign: 'left', padding: "10px"}}>
                                            {tableData.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{padding: '20px'}}>
                                                        <a href={`https://finance.mrfreire.net/security_search/security_search_results/${item.symbol}`}>{item.symbol}</a>
                                                    </td>
                                                    <td style={{padding: '20px', textAlign:"center"}}>{item.title}</td>

                                                </tr>
                                            ))}
                                            </tbody>

                                        </table>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab2">
                                        {/* Content for Tab 2 */}
                                        <table className={tableClassName}
                                               style={{color: "white", margin: "50px", alignContent: "center"}}>
                                            <thead>
                                            <tr>
                                                <th>Ticker</th>
                                                <th>Name</th>
                                            </tr>
                                            </thead>
                                            <tbody className="small" style={{textAlign: 'left', padding: "40px"}}>
                                            {tableData2.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{padding: '7px'}}>
                                                        <a href={`https://finance.mrfreire.net/security_search/security_search_results/${item.symbol}-USD`}>{item.symbol}</a>
                                                    </td>
                                                    <td style={{padding: '7px'}}>{item.title}</td>

                                                </tr>
                                            ))}
                                            </tbody>

                                        </table>
                                    </Tab.Pane>

                                </Tab.Content>
                            </Tab.Container>


                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );


}

export default Table;