import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
    return (<Card className="quote-card-view">
        <Card.Body>
            <h2>Introduction</h2>
            <blockquote className="small">
                <p style={{textAlign: "left"}}>
                    With more than a decade of international experience in product management, software development,
                    and business analysis, I am a skilled engineer with a passion for delivering innovative
                    solutions to clients. Throughout my career, I have held middle-management positions and
                    successfully led top-tier projects across various locations.


                </p>

                <p style={{textAlign: "left"}}>
                    My areas of expertise include product development, project management, software development, and
                    investment management. I am a creative thinker who is always looking for ways to improve
                    products, whether by listening to clients or collaborating with colleagues. Additionally, I am
                    committed to continuous learning and staying up-to-date on new technologies and best practices
                    in my field.

                </p>

                <p style={{textAlign: "left"}}>

                    Since 2012 delivering passion and new ideas to different companies I have worked for, including
                    our valued clients.


                    I’m an engineer with more around 10 years of international experience in product management,
                    software development and
                    business analysis for different companies. More than half of my experience is in
                    middle-management positions.

                    I had the chance of being involved and leading several top-tier projects across different
                    locations.

                    What I like most about my job is combining the three main aspects I am passionate about:

                </p>

                <ul style={{textAlign:"left"}}>
                    <li>Product Development and Project Management</li>
                    <li>Software Development & Applications</li>
                    <li>Finance and Investment Management</li>


                </ul>
            </blockquote>
            <p></p>
            <p></p>
            <h2>Other interests</h2>
            <blockquote className="small"  style={{textAlign: "left"}}>

                <p>
                    Even I consider myself a creative person, I also like taking ideas from different situations, in
                    order to look for any
                    aspect that will improve our products. In order to do so, I also think listening both clients and
                    colleagues in order to
                    get the best from the mix is one of my best attributes.
                </p>
                <p>

                    I think the best investment a person can make is education and continuous learning. For that reason,
                    I'm constantly
                    learning about new topics in order to apply them into my job or my personal projects.
                    In my spare time you will, these are some of the activities I enjoy most:
                </p>

                <ul style={{justifyContent: 'left', textAlign: 'left'}}>
                    <li>Jogging</li>
                    <li>Investment</li>
                    <li>Video games</li>
                    <li>Body workout</li>
                    <li>Swimming</li>
                    <li>Reading</li>
                    <li>Learning</li>
                    <li>Cycling</li>
                    <li>Trail running</li>
                    <li>Hiking</li>
                    <li>Traveling</li>

                </ul>

            </blockquote>
        </Card.Body>
    </Card>);
}

export default AboutCard;
