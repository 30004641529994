import React from "react";
import Card from "react-bootstrap/Card";

function CourseCard(props) {
  return (
            <a href={props.link} target="_blank" rel="noopener noreferrer">

    <Card className="course-card-view">
                      <div style={{ display: "flex", justifyContent: "center" }}>
      <Card.Img variant="course" src={props.imgPath} alt="card-img-course"/>
                                        </div>

      <Card.Body>
        <Card.Body className={"small"} style={{height: "6.2em"}}>
          <strong>{props.title}</strong> </Card.Body>
        <Card.Body className={"small"} style={{ textAlign: "left" }}>
         <strong>{props.university}</strong>
        </Card.Body>
        </Card.Body>
<p></p>

    </Card>
            </a>
  );
}
export default CourseCard;
