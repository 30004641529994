import React, {useEffect, useState} from "react";
import {Col, Container, Pagination, Row} from "react-bootstrap";
import RealmService from "../Auctions/RealmService";
import CredentialsAPI from "../Credentials/CredentialsAPI";
import CryptoCard from "../Crypto/CryptoCard";

const auctionsService = new RealmService('funds-ekuzl');
const ITEMS_PER_PAGE = 36;

const Crypto = () => {
    const [queryResult, setQueryResult] = useState([]);
    const [activeTab, setActiveTab] = useState('tab1');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        const fetchData = async () => {
            await auctionsService.initialize('funds-ekuzl', CredentialsAPI.API_FUNDS);

            const mongodb = await auctionsService.getMongoClient();
            if (mongodb) {
                const collection = mongodb.db('Finance').collection('Crypto');
                const query = {'name': {'$exists': true}};
                if (searchQuery) {
                    query.name = {'$regex': searchQuery, '$options': 'i'};
                }
                const totalCount = await collection.count(query);
                setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));

                const skip = (currentPage - 1) * ITEMS_PER_PAGE;
                const crypto = await collection.find(query);
                const paginatedCrypto = crypto.slice(skip, skip + ITEMS_PER_PAGE);

                setQueryResult(paginatedCrypto);
            }
        };

        fetchData();
    }, [currentPage, searchQuery]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const filteredCrypto = queryResult.filter((crypto) =>
        crypto.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            <Container fluid className="project-section">
                <Container>
                    <h1 className="project-heading">
                        <strong>Crypto Information</strong>
                    </h1>
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search..."
                    />
                    <Row style={{justifyContent: "center", paddingBottom: "2px"}}>
                        {filteredCrypto.map((crypto, index) => (
                            <Col lg={2} md={6} sm={10} xs={12} className="book-card" key={crypto._id}>
                                <CryptoCard
                                    isBlog={false}
                                    name={crypto.name}
                                    summaryCrypto={crypto.summaryCrypto ? crypto.summaryCrypto.trim() : ''}
                                    technical={crypto.technical}
                                    reddit={crypto.reddit}
                                    exchanges={crypto.exchanges ? crypto.exchanges.join(' ') : ''}
                                />
                            </Col>
                        ))}
                    </Row>
                    {totalPages > 1 && (
                        <Pagination>
                            {Array.from({length: totalPages}, (_, index) => (
                                <Pagination.Item
                                    key={index + 1}
                                    active={currentPage === index + 1}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    )}
                </Container>
            </Container>
        </div>
    );
};

export default Crypto;
